var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-navigation", {
        attrs: {
          title: _vm.$route.params.id
            ? _vm.$t("intercom.edit-contacts")
            : _vm.$t("intercom.add-contacts"),
          backButtonText: _vm.$t("navigation.back"),
          actionButtonIcon: "fa fa-upload"
        },
        on: { action: _vm.addContact }
      }),
      _c(
        "el-form",
        {
          ref: "contactForm",
          staticClass: "form-container",
          attrs: {
            model: _vm.contactData,
            "label-position": "left",
            "label-width": "200px",
            "status-icon": ""
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "400px" },
                      attrs: {
                        prop: "name",
                        label: _vm.$t("intercom.name"),
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("intercom.form-rules.type-name")
                          }
                        ]
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("intercom.type-name")
                        },
                        model: {
                          value: _vm.contactData.name,
                          callback: function($$v) {
                            _vm.$set(_vm.contactData, "name", $$v)
                          },
                          expression: "contactData.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "400px" },
                      attrs: {
                        prop: "number",
                        label: _vm.$t("intercom.number-ip-address"),
                        rules: [
                          {
                            required: true,
                            message: _vm.$t(
                              "intercom.form-rules.type-number-ip"
                            )
                          }
                        ]
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("intercom.type-number")
                        },
                        model: {
                          value: _vm.contactData.number,
                          callback: function($$v) {
                            _vm.$set(_vm.contactData, "number", $$v)
                          },
                          expression: "contactData.number"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("intercom.icon") } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: _vm.$t("intercom.select-icon")
                              },
                              model: {
                                value: _vm.contactData.icon,
                                callback: function($$v) {
                                  _vm.$set(_vm.contactData, "icon", $$v)
                                },
                                expression: "contactData.icon"
                              }
                            },
                            _vm._l(_vm.icons, function(icon, index) {
                              return _c(
                                "el-option",
                                {
                                  key: index,
                                  attrs: {
                                    label: icon.title,
                                    value: icon.value
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "icon-select-layout" },
                                    [
                                      _c("img", {
                                        staticClass: "icon-select",
                                        attrs: {
                                          src:
                                            "/assets/icons/" +
                                            icon.value +
                                            ".png"
                                        },
                                        on: { error: _vm.onImageError }
                                      }),
                                      _vm._v(_vm._s(icon.title))
                                    ]
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("span", [
                          _c("img", {
                            staticClass: "icon-contact",
                            attrs: {
                              src:
                                "/assets/icons/" + _vm.contactData.icon + ".png"
                            },
                            on: { error: _vm.onImageError }
                          })
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "400px" },
                      attrs: {
                        prop: "favorite",
                        label: _vm.$t("intercom.favorite")
                      }
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.contactData.favorite,
                          callback: function($$v) {
                            _vm.$set(_vm.contactData, "favorite", $$v)
                          },
                          expression: "contactData.favorite"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("intercom.contact-type") } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("intercom.select-type"),
                            filterable: ""
                          },
                          on: { change: _vm.onContactTypeChanged },
                          model: {
                            value: _vm.contactData.contactType,
                            callback: function($$v) {
                              _vm.$set(_vm.contactData, "contactType", $$v)
                            },
                            expression: "contactData.contactType"
                          }
                        },
                        _vm._l(_vm.contactTypes, function(type) {
                          return _c("el-option", {
                            key: type.value,
                            attrs: { label: type.title, value: type.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "400px" },
                      attrs: {
                        prop: "shortcut",
                        label: _vm.$t("intercom.shortcut")
                      }
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.contactData.shortcut,
                          callback: function($$v) {
                            _vm.$set(_vm.contactData, "shortcut", $$v)
                          },
                          expression: "contactData.shortcut"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.showAdvanced
            ? _c(
                "div",
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "400px" },
                              attrs: {
                                prop: "rtspUrl",
                                label: _vm.$t("intercom.rtsp-url")
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.$t("intercom.type-rtsp")
                                },
                                model: {
                                  value: _vm.contactData.rtspUrl,
                                  callback: function($$v) {
                                    _vm.$set(_vm.contactData, "rtspUrl", $$v)
                                  },
                                  expression: "contactData.rtspUrl"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "400px" },
                              attrs: {
                                prop: "snapshotUrl",
                                label: _vm.$t("intercom.snapshot-url")
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.$t("intercom.type-snapshot")
                                },
                                model: {
                                  value: _vm.contactData.snapshotUrl,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.contactData,
                                      "snapshotUrl",
                                      $$v
                                    )
                                  },
                                  expression: "contactData.snapshotUrl"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "400px" },
                              attrs: { label: _vm.$t("intercom.username") }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.$t("intercom.type-username")
                                },
                                model: {
                                  value: _vm.username,
                                  callback: function($$v) {
                                    _vm.username = $$v
                                  },
                                  expression: "username"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "400px" },
                              attrs: { label: _vm.$t("intercom.password") }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.$t("intercom.type-password")
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.contactData.contactType == "custom"
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { width: "400px" },
                                  attrs: {
                                    label: _vm.$t(
                                      "intercom.display-cam-on-call"
                                    )
                                  }
                                },
                                [
                                  _c("el-switch", {
                                    model: {
                                      value:
                                        _vm.contactData.displayCameraOnCall,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contactData,
                                          "displayCameraOnCall",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "contactData.displayCameraOnCall"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.contactData.actions, function(action, index) {
                    return _c(
                      "el-row",
                      {
                        key: index,
                        staticStyle: { "margin-top": "20px" },
                        attrs: { gutter: 10 }
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c("el-switch", {
                              staticStyle: { "margin-top": "10px" },
                              attrs: {
                                "active-text": _vm.$t("intercom.shortcut")
                              },
                              model: {
                                value: action.shortcut,
                                callback: function($$v) {
                                  _vm.$set(action, "shortcut", $$v)
                                },
                                expression: "action.shortcut"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "120px" },
                                attrs: {
                                  placeholder: _vm.$t("intercom.select-type"),
                                  filterable: ""
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.changeActionType(action)
                                  }
                                },
                                model: {
                                  value: action.actionType,
                                  callback: function($$v) {
                                    _vm.$set(action, "actionType", $$v)
                                  },
                                  expression: "action.actionType"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    value: "DTMF",
                                    label: _vm.$t("intercom.dtmf")
                                  }
                                }),
                                _c("el-option", {
                                  attrs: {
                                    value: "HTTP",
                                    label: _vm.$t("intercom.http")
                                  }
                                }),
                                _c("el-option", {
                                  attrs: {
                                    value: "ACCESSORY",
                                    label: _vm.$t("intercom.accessory")
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 5 } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("intercom.action-name")
                              },
                              model: {
                                value: action.name,
                                callback: function($$v) {
                                  _vm.$set(action, "name", $$v)
                                },
                                expression: "action.name"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            action.actionType != "ACCESSORY"
                              ? _c("el-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder:
                                      action.actionType == "DTMF"
                                        ? _vm.$t("intercom.type-dtmf")
                                        : _vm.$t("intercom.type-http")
                                  },
                                  model: {
                                    value: action.params.command,
                                    callback: function($$v) {
                                      _vm.$set(action.params, "command", $$v)
                                    },
                                    expression: "action.params.command"
                                  }
                                })
                              : _vm._e(),
                            action.actionType == "ACCESSORY"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "intercom.select-accessory"
                                          ),
                                          filterable: ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.changeAccessory(action)
                                          }
                                        },
                                        model: {
                                          value: action.params.id,
                                          callback: function($$v) {
                                            _vm.$set(action.params, "id", $$v)
                                          },
                                          expression: "action.params.id"
                                        }
                                      },
                                      _vm._l(_vm.accessories, function(
                                        accessory
                                      ) {
                                        return _c("el-option", {
                                          key: accessory.id,
                                          attrs: {
                                            value: accessory.id,
                                            label: accessory.name
                                          }
                                        })
                                      }),
                                      1
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: {
                                          width: "100px",
                                          "margin-left": "10px"
                                        },
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "intercom.select-command"
                                          ),
                                          filterable: ""
                                        },
                                        model: {
                                          value: action.params.command,
                                          callback: function($$v) {
                                            _vm.$set(
                                              action.params,
                                              "command",
                                              $$v
                                            )
                                          },
                                          expression: "action.params.command"
                                        }
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            value: "ON",
                                            label: _vm.$t("intercom.action-on")
                                          }
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            value: "OFF",
                                            label: _vm.$t("intercom.action-off")
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 1 } },
                          [
                            _c("image-picker", {
                              attrs: {
                                action: action,
                                selectedImage: action.icon,
                                images: _vm.actionIcons.map(function(icon) {
                                  return icon.value
                                })
                              },
                              on: { selectImage: _vm.selectImage }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 2 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.contactData.actions.splice(
                                      index,
                                      1
                                    )
                                  }
                                }
                              },
                              [_c("i", { staticClass: "far fa-trash-alt" })]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.addAction }
                    },
                    [_vm._v(_vm._s(_vm.$t("intercom.add-action")))]
                  )
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }