<template>
  <div>
    <app-navigation :title="$route.params.id ? $t('intercom.edit-contacts') : $t('intercom.add-contacts')" :backButtonText="$t('navigation.back')" actionButtonIcon="fa fa-upload" @action="addContact"></app-navigation>

    <el-form :model="contactData" ref="contactForm" class="form-container" label-position="left" label-width="200px" status-icon>

      <el-row>
        <el-col :span="12">
          <el-form-item prop="name" :label="$t('intercom.name')" style="width: 400px" :rules="[{required: true, message: $t('intercom.form-rules.type-name')}]">
            <el-input type="text" v-model="contactData.name" :placeholder="$t('intercom.type-name')">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="number" :label="$t('intercom.number-ip-address')" style="width: 400px" :rules="[{required: true, message: $t('intercom.form-rules.type-number-ip')}]">
            <el-input type="text" v-model="contactData.number" :placeholder="$t('intercom.type-number')">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('intercom.icon')">

            <el-col :span="16">
              <el-select v-model="contactData.icon" filterable :placeholder="$t('intercom.select-icon')">
                <el-option v-for="(icon, index) in icons" :key="index" :label="icon.title" :value="icon.value">
                  <span class="icon-select-layout"><img @error="onImageError" :src="'/assets/icons/' + icon.value + '.png'" class="icon-select">{{icon.title}}</span>
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="8">
              <span>
                <img @error="onImageError" :src="'/assets/icons/' + contactData.icon + '.png'" class="icon-contact">
              </span>
            </el-col>

          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="favorite" :label="$t('intercom.favorite')" style="width: 400px">
            <el-switch v-model="contactData.favorite"></el-switch>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">

          <el-form-item :label="$t('intercom.contact-type')">
            <el-select v-model="contactData.contactType" @change="onContactTypeChanged" :placeholder="$t('intercom.select-type')" filterable>
              <el-option v-for="type in contactTypes" :label="type.title" :value="type.value" :key="type.value" ></el-option>
            </el-select>
          </el-form-item>

        </el-col>
        <el-col :span="12">

          <el-form-item prop="shortcut" :label="$t('intercom.shortcut')" style="width: 400px">
            <el-switch v-model="contactData.shortcut"></el-switch>
          </el-form-item>

        </el-col>
      </el-row>





      <div v-if="showAdvanced">

        <el-row>
          <el-col :span="12">
            <el-form-item prop="rtspUrl" :label="$t('intercom.rtsp-url')" style="width: 400px">
              <el-input type="text" v-model="contactData.rtspUrl" :placeholder="$t('intercom.type-rtsp')">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="snapshotUrl" :label="$t('intercom.snapshot-url')" style="width: 400px">
              <el-input type="text" v-model="contactData.snapshotUrl" :placeholder="$t('intercom.type-snapshot')">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('intercom.username')" style="width: 400px">
              <el-input type="text" v-model="username" :placeholder="$t('intercom.type-username')">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('intercom.password')" style="width: 400px">
              <el-input type="text" v-model="password" :placeholder="$t('intercom.type-password')">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="contactData.contactType == 'custom'">
          <el-col :span="12">
            <el-form-item :label="$t('intercom.display-cam-on-call')" style="width: 400px">
              <el-switch v-model="contactData.displayCameraOnCall"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-for="(action, index) in contactData.actions" :key="index" style="margin-top: 20px" :gutter="10">
          <el-col :span="4">
            <el-switch v-model="action.shortcut" :active-text="$t('intercom.shortcut')" style="margin-top: 10px"></el-switch>
          </el-col>

          <el-col :span="4">
            <el-select v-model="action.actionType" :placeholder="$t('intercom.select-type')" filterable style="width: 120px" @change="changeActionType(action)">
              <el-option value="DTMF" :label="$t('intercom.dtmf')"></el-option>
              <el-option value="HTTP" :label="$t('intercom.http')"></el-option>
              <el-option value="ACCESSORY" :label="$t('intercom.accessory')"></el-option>
            </el-select>
          </el-col>


          <el-col :span="5">

            <el-input type="text" v-model="action.name" :placeholder="$t('intercom.action-name')">

              <!--<el-select v-model="action.icon" filterable :placeholder="$t('intercom.select-icon')" slot="prepend" style="width: 120px">-->
                <!--<el-option v-for="(icon, index) in actionIcons" :key="index" :label="icon.title" :value="icon.value">-->
                  <!--<span class="icon-select-layout"><img @error="onImageError" :src="'/assets/icons/' + icon.value + '.png'" class="icon-select">&lt;!&ndash;<i :class="icon.value"></i>&ndash;&gt; {{ icon.title}}</span>-->
                <!--</el-option>-->
              <!--</el-select>-->

            </el-input>
          </el-col>

          <el-col :span="8">
            <el-input v-if="action.actionType != 'ACCESSORY'" type="text" v-model="action.params.command" :placeholder="action.actionType == 'DTMF' ? $t('intercom.type-dtmf') : $t('intercom.type-http')">
            </el-input>

            <div v-if="action.actionType == 'ACCESSORY'">
              <el-select v-model="action.params.id" :placeholder="$t('intercom.select-accessory')" filterable @change="changeAccessory(action)">

                <el-option v-for="accessory in accessories" :value="accessory.id" :label="accessory.name" :key="accessory.id"></el-option>

              </el-select>

              <el-select v-model="action.params.command" :placeholder="$t('intercom.select-command')" filterable style="width: 100px; margin-left: 10px">
                <el-option value="ON" :label="$t('intercom.action-on')"></el-option>
                <el-option value="OFF" :label="$t('intercom.action-off')"></el-option>
              </el-select>

            </div>
          </el-col>

          <el-col :span="1">

            <image-picker :action="action" :selectedImage="action.icon" :images="actionIcons.map(icon => icon.value)" @selectImage="selectImage"></image-picker>

            <!--<div @click="visiblePicker != visiblePicker" v-popover:picker="">-->
              <!--<img @error="onImageError" :src="'/assets/icons/' + action.icon + '.png'" class="icon-contact">-->
            <!--</div>-->
          </el-col>

          <el-col :span="2">
            <el-button @click="contactData.actions.splice(index, 1)" type="danger"><i class="far fa-trash-alt"></i></el-button>
          </el-col>

        </el-row>


        <el-button @click="addAction" type="primary" style="margin-top: 20px">{{ $t('intercom.add-action') }}</el-button>

      </div>

    </el-form>
  </div>
</template>

<script>
import ImagePicker from "@/components/ImagePicker";
import Navigation from "@/components/Navigation";
import { METHOD_ADD_CONTACT, METHOD_EDIT_CONTACT } from "@/store/method-names";
import { className } from "../../utils/accessory-utils";

export default {
  name: "NewContact",
  components: {
    appNavigation: Navigation,
    ImagePicker,
  },

  data: function() {
    return {
      visiblePicker: false,
      contactData: {
        name: "",
        icon: "person",
        number: "", //number or ip address
        favorite: false,
        shortcut: false,
        contactType: "standart", //standart, akuvox, 2n, custom
        rtspUrl: "",
        snapshotUrl: "",
        actions: [],
        displayCameraOnCall: false,
      },

      username: "",
      password: "",
      label: "<span><i class='fa fa-user-circle'></i></span>",

      contactTypes: [
        { title: this.$t("intercom.contact-types[0]"), value: "standart" },
        { title: this.$t("intercom.contact-types[1]"), value: "akuvox" },
        { title: this.$t("intercom.contact-types[2]"), value: "2n" },
        { title: this.$t("intercom.contact-types[3]"), value: "custom" },
      ],

      icons: [
        { title: this.$t("intercom.icons[0]"), value: "person" },
        { title: this.$t("intercom.icons[1]"), value: "bussiness" },
        { title: this.$t("intercom.icons[2]"), value: "reception" },
        { title: this.$t("intercom.icons[3]"), value: "intercom" },
        { title: this.$t("intercom.icons[4]"), value: "security" },
        { title: this.$t("intercom.icons[5]"), value: "sos" },
      ],

      actionIcons: [
        { title: this.$t("intercom.action-icons[0]"), value: "garage_door" },
        {
          title: this.$t("intercom.action-icons[1]"),
          value: "lighting_circle",
        },
        { title: this.$t("intercom.action-icons[2]"), value: "sos" },
        { title: this.$t("intercom.action-icons[3]"), value: "unlock_door" },
      ],
    };
  },

  computed: {
    showAdvanced: function() {
      return this.contactData.contactType != "standart";
    },
    accessories: function() {
      return this.$store.getters.accessories.filter(
        (accessory) => className(accessory.category) == "HHAccessorySwitch"
      );
    },
  },

  watch: {
    username: function(newVal, oldVal) {
      this.setUrls();
    },
    password: function(newVal, oldVal) {
      this.setUrls();
    },

    "contactData.contactType": function(newVal, oldVal) {
      if (newVal == "akuvox") {
        this.contactData.rtspUrl = this.$route.params.id
          ? this.contactData.rtspUrl
          : "rtsp://" + this.contactData.number + "/live/ch00_0";
        this.contactData.snapshotUrl = this.$route.params.id
          ? this.contactData.snapshotUrl
          : "http://" + this.contactData.number + ":8080/picture.jpg";
      } else if (newVal == "2n") {
        this.contactData.rtspUrl = this.$route.params.id
          ? this.contactData.rtspUrl
          : "rtsp://" + this.contactData.number + "/h264_stream";
        this.contactData.snapshotUrl = this.$route.params.id
          ? this.contactData.snapshotUrl
          : "http://" + this.contactData.number + "/snapshot/view0.jpg";
      } else if (newVal == "custom") {
        this.contactData.rtspUrl = this.contactData.rtspUrl
          ? this.contactData.rtspUrl
          : "";
        this.contactData.snapshotUrl = this.contactData.snapshotUrl
          ? this.contactData.snapshotUrl
          : "";
      } else {
        this.contactData.rtspUrl = "";
        this.contactData.snapshotUrl = "";
      }

      this.username = "";
      this.password = "";

      console.log("contact watch", this.contactData);
    },
  },

  methods: {
    className,

    onImageError: function(e) {
      console.log("on image error");
      e.target.src = "/assets/person.png";
    },

    selectImage: function(image, action) {
      console.log("selected image", image);
      action.icon = image;
    },

    changeActionType: function(action) {
      action.params = {};
    },

    changeAccessory: function(action) {
      console.log("change Accessory action", action);

      const accessory = this.accessories.find(
        (accessory) => accessory.id == action.params.id
      );

      console.log("change Accessory accessory", accessory);

      if (accessory) {
        action.name = accessory.name;
        action.icon = accessory.icon;
      }
    },

    onContactTypeChanged(){
      console.log("WORKKKKK")
      this.contactData.displayCameraOnCall = false
    },

    checkActions: function() {
      var valid = true;

      if (this.contactData.actions && this.contactData.actions.length > 0) {
        var message = this.$t("intercom.form-rules.check-fields");

        this.contactData.actions.forEach((action) => {
          var msg = [];
          if (!action.name) {
            valid = false;
            msg.push(this.$t("intercom.form-rules.name-empty"));
          }

          if (action.actionType == "DTMF") {
            if (!action.params.command) {
              valid = false;
              msg.push(this.$t("intercom.form-rules.dtmf-empty"));
            }
          } else if (action.actionType == "HTTP") {
            if (!action.params.command) {
              valid = false;
              msg.push(this.$t("intercom.form-rules.url-empty"));
            }
          } else if (action.actionType == "ACCESSORY") {
            if (!action.params.command) {
              valid = false;
              msg.push(this.$t("intercom.form-rules.command-empty"));
            }
            if (!action.params.id) {
              valid = false;
              msg.push(this.$t("intercom.form-rules.accessory-empty"));
            }
          }

          if (msg.length > 0) {
            const msgStr = msg.join(", ");
            message += "Action(" + action.actionType + ") " + msgStr + "<br>";
          }
        });

        if (!valid) {
          this.$message({
            message: message,
            type: "error",
            dangerouslyUseHTMLString: true,
          });
        }
      }

      return valid;
    },

    validateIPaddress: function(ipaddress) {
      if (
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          ipaddress
        )
      ) {
        return true;
      }
      return false;
    },

    addContact: function() {
      this.$refs.contactForm.validate((valid) => {
        if (valid && this.checkActions()) {
          const sipServer = this.$store.getters.intercomSettings.sipServer;
          if (sipServer && sipServer.enabled && sipServer.serverUrl) {
            if (this.validateIPaddress(this.contactData.number)) {
              this.contactData.sipAddress =
                "sip:100@" + this.contactData.number;
            } else {
              this.contactData.sipAddress = `sip:${this.contactData.number}@${
                sipServer.serverUrl
              }:${sipServer.port}`;
            }
          } else {
            this.contactData.sipAddress = "sip:100@" + this.contactData.number;
          }
          console.log("sipAddress", this.contactData.sipAddress);
          if (this.$route.params.id) {
            this.$store
              .dispatch(METHOD_EDIT_CONTACT, this.contactData)
              .then((response) => {
                this.$message({
                  message: this.$t(response.message),
                  type: "success",
                });
                this.$router.push({ name: "Contacts" });
              })
              .catch((reason) => {
                this.$message({
                  message: this.$t(reason),
                  type: "error",
                });
              });
          } else {
            this.$store
              .dispatch(METHOD_ADD_CONTACT, this.contactData)
              .then((response) => {
                this.$message({
                  message: this.$t(response.message),
                  type: "success",
                });
                this.$router.push({ name: "Contacts" });
              })
              .catch((reason) => {
                this.$message({
                  message: this.$t(reason),
                  type: "error",
                });
              });
          }
        }
      });
    },

    addAction: function() {
      this.contactData.actions.push({
        name: "",
        icon: this.actionIcons[0].value,
        actionType: "DTMF",
        shortcut: false,
        params: {},
      });
    },

    setUrls() {
      let rtspUrlItems = this.contactData.rtspUrl.split("//");
      let snapUrlItems = this.contactData.snapshotUrl.split("//");

      let rtspPostfix = !rtspUrlItems[1].includes("@")
        ? rtspUrlItems[1]
        : rtspUrlItems[1].split("@").length > 1
          ? rtspUrlItems[1].split("@")[1]
          : rtspUrlItems[1].split("@")[0];

      let snapPostfix = !snapUrlItems[1].includes("@")
        ? snapUrlItems[1]
        : snapUrlItems[1].split("@").length > 1
          ? snapUrlItems[1].split("@")[1]
          : snapUrlItems[1].split("@")[0];

      rtspPostfix = !rtspPostfix ? "" : rtspPostfix;
      snapPostfix = !snapPostfix ? "" : snapPostfix;

      let auth = this.username + ":" + this.password + "@";
      auth = auth.length == 2 ? "" : auth;

      this.contactData.rtspUrl = rtspUrlItems[0] + "//" + auth + rtspPostfix;
      this.contactData.snapshotUrl =
        snapUrlItems[0] + "//" + auth + snapPostfix;

      console.log("setUrls", this.contactData);
    },
  },

  created() {
    if (this.$route.params.id) {
      this.contactData = this.$store.getters.contacts.find(
        (contact) => contact.id == this.$route.params.id
      );
    }

    this.$store.dispatch("getAccessories");
  },
};
</script>

<style scoped>
.form-container {
  margin-right: 20px;
  margin-left: 20px;
  padding: 25px 0px 25px 25px;
  border: 1px solid #eaeaea;
  background-color: #fff;
  border-radius: 10px;
}

.icon-select-layout {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon-select {
  filter: brightness(0.45);
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.icon-contact {
  filter: brightness(0.45);
  width: 30px;
  height: 30px;
  margin: 5px;
}
</style>
